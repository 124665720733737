<template>
  <div class="farm_service_detail">
    <div class="title">农资详情</div>
    <a-spin :spinning="loading">
      <a-row :gutter="50">
        <a-col :span="11">
          <div class="pic">
            <a-carousel arrows dots-class="slick-dots slick-thumb">
              <a slot="customPaging" slot-scope="props">
                <img :src="detail.imageFiles.length>0?detail.imageFiles[props.i].url:fileUrl.defaultUrl" />
              </a>
              <div v-for="(item, index) in detail.imageFiles.length>0? detail.imageFiles:1" :key="index">
                <img class="img_info" :src="item.url!=''?item.url:fileUrl.defaultUrl" />
              </div>
            </a-carousel>
          </div>
        </a-col>
        <a-col :span="13">
          <div class="info">
            <div class="tit">{{ detail.name }}</div>
            <p>
              <span>价格：</span
              ><b>{{ detail.price }}/{{ detail.unit }}</b>
            </p>
            <p><span>分类：</span>{{ detail.paramValues[0]?detail.paramValues[0].paramValue:"未添加分类" }}</p>
            <p><span>品牌：</span>{{ detail.paramValues[1]?detail.paramValues[1].paramValue:"未添加品牌" }}</p>
            <p><span>数量：</span>{{ detail.count }}</p>
            <p><span>发布时间：</span>{{ detail.gmtCreate }}</p>
            <p><span>浏览次数：</span>{{ detail.clicks }}</p>
            <div class="btns">
              <a-button
                :style="{
                  backgroundColor: '#69cba5',
                  borderColor: '#69cba5',
                  marginRight: '20px',
                }"
                type="primary"
                shape="round"
                icon="phone"
              >
                立即联系
              </a-button>
              <a-button
                :style="{ backgroundColor: '#f6985a', borderColor: '#fa4603' }"
                type="primary"
                shape="round"
                icon="shopping-cart"
              >
                立即下单
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="title">详细资料</div>
      <div class="detail" >
          <div class="detail_content" v-html="detail.content"></div>
      </div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/farm_service";
import image from "@/assets/images/tuolaji.png";
export default {
  data() {
    return {
      loading: false,
      detail: null,
      fileUrl:{
        url:'',
        defaultUrl:require('@/assets/images/image3.png'),
      },
    };
  },
  components: {},
  created() {
    this.getDetail();
  },
  methods: {
    getImgUrl() {
      return image;
    },
    getDetail() {
      this.loading = true;
      Api.goodsDetail(this.$route.params.id).then((res) => {
        this.loading = false;
        this.detail = res.result;
        Api.hitsCurrentGoods(this.$route.params.id);
      });
    },
  },
};
</script>
<style lang="scss">
.farm_service_detail {
  .pic {
    padding-bottom: 60px;
  }
  .slick-dots {
    height: auto;
  }
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  }
  .slick-thumb {
    bottom: -60px;
    display: flex !important;
  }
  .slick-thumb li {
    flex: 1;
    display: block;
    height: 40px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 75px;
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
}
</style>
<style scoped lang="scss">
.farm_service_detail {
  .title {
    line-height: 36px;
    background: url("~@/assets/images/lx.png") no-repeat 10px center #f9f9f9;
    font-size: 16px;
    color: #008840;
    text-indent: 40px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .pic {
    .img_info {
      width: 100%;
      height: 220px;
    }
  }
  .info {
    .tit {
      line-height: 2.5em;
      font-size: 24px;
      color: #008840;
      font-weight: bold;
    }
    p {
      line-height: 1.6em;
      b {
        color: #008840;
        font-size: 1.6em;
      }
    }
  }
  .detail {
    line-height: 2em;
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .detail_content{
      overflow:auto;
  }
}
</style>
